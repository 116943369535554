import { createRouter as _createRouter, createWebHistory, Router } from 'vue-router'

const createRouter = (): Router => {
  return _createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/auth/callback',
        name: 'auth-callback',
        component: import('@/components/LoadingBlock.vue'),
        meta: {
          skipAuthentication: true,
        },
      },
      /*{
        path: '/',
        name: 'dashboard',
        components: {
          ...defaultComponents,
          header: () => import('@/views/Header.vue'),
          main: () => import('@/views/Home.vue'),
        },
      },*/
      {
        path: '/shared/:uri',
        name: 'yachtnote',
        components: {
          header: () => import('@/views/Header.vue'),
          main: () => import('@/views/YachtNote.vue'),
        },
        props: {
          main: (route) => ({
            uri: route.params.uri.toString(),
          }),
        },
      },
      {
        path: '/unauthorized',
        name: 'unauthorized',
        components: {
          main: () => import('@/views/Unauthorized.vue'),
        },
        meta: {
          skipAuthentication: true,
        },
      },
    ],
  })
}

export default createRouter
