import { Plugin } from 'vue'

import { SentryConfig } from '@ankor-io/sentry-conflux/src/types'

import { AuthenticationClientOptions } from '@/iam/types'

/**
 * The config type definition
 */

export declare type Config = {
  /**
   * Kinde client related configurations
   */
  AUTHENTICATION_CLIENT_OPTIONS: AuthenticationClientOptions

  /**
   * Stowage Absolute URL
   */
  STOWAGE_ABSOLUTE_URL: string

  /**
   * Sentry tracing settings
   */
  SENTRY: SentryConfig
}

// intersect our main type with a Vue app plugin for using it in the app.
export type AppConfig = Config & Plugin
export const ConfigKey: string = 'config'
