<script setup lang="ts">
import { computed, onBeforeMount } from 'vue'
import { RouterView, useRoute } from 'vue-router'

/**
 * Convert the configured component names in the router to a set.
 */
const components = computed(() => {
  const route = useRoute()
  // flatmap out the names of the components needed.
  // pop them into a Set<> for uniqueness.
  return new Set(route.matched.flatMap((c) => (c?.components ? Object.keys(c.components) : [])))
})

onBeforeMount(() => {
  // read from browser setting of dark theme and move to session storage.
  const storageValue = sessionStorage.getItem('prefers-color-scheme')
  if (storageValue === 'dark' || window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.classList.add('dark')
  }
})
</script>

<template>
  <div>
    <header v-if="components.has('header')" class="page-header">
      <RouterView name="header" />
    </header>
    <main
      v-if="components.has('main')"
      id="main-content"
      class="relative max-w-7xl w-full h-full mx-auto px-4 sm:px-20 overflow-y-auto bg-gray-white dark:bg-gray-900 dark:text-white"
    >
      <RouterView name="main" />
    </main>
  </div>
</template>
